import { registerApplication, start } from "single-spa";
import { state, axiosIns } from "@app/utility";

let userToken = null;
let shareComponentsLoaded = false;

function getCookie(cookieName) {
  var cookies = document.cookie.split(";");

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i].trim();

    if (cookie.indexOf(cookieName) === 0) {
      var nameValue = cookie.split("=");
      var name = nameValue[0];
      var value = nameValue[1];

      return {
        name: name,
        value: value,
      };
    }
  }
  return null;
}

var cookie = getCookie("dronos-token");
if (cookie) {
  userToken = cookie.value;
} else {
  userToken = null;
}

const userLoggedIn = localStorage.getItem("dronos-token") !== null;
const userAccount =
  localStorage.getItem("user-account") !== null
    ? localStorage.getItem("user-account")
    : "paid";
const roles = [
  { id: 1, name: "admin" },
  { id: 2, name: "editor" },
  { id: 3, name: "viewer" },
];

const userRole = [roles[0]];
localStorage.setItem("user-role", JSON.stringify(userRole));

const props = {
  mainApp: "dronos",
  userAuth: userLoggedIn,
};
const childAppRoutes = ["canvas", "common", "/change-password"];
const specialAllowedRoutes = [
  "/canvas/amplitel/digital-twin",
  "/canvas/amplitel/from-above",
  "/canvas/amplitel/los",
  "/canvas/amplitel/side-view",
  "/canvas/amplitel/tower-view",
];
const publicRoutes = [
  "/login",
  "/register",
  "/forgot-password",
  "/reset-password",
  "/no-workspace",
];
const privateRoutes = [
  "/asset-management",
  "/common",
  "/change-password",
  "/canvas",
];
if (!userLoggedIn) {
  registerApplication({
    name: "@app/auth",
    app: loadApp("@app/auth"),
    activeWhen: ["/"],
  });
} else {
  registerApplication({
    name: "@app/auth",
    app: loadApp("@app/auth"),
    activeWhen: ["/change-password"],
  });

  registerApplication({
    name: "@utm-national",
    app: loadApp("@utm-national"),
    activeWhen: () => !childAppRoutes.includes(location.pathname.split("/")[1]),
    customProps: () => {
      return props;
    },
  });

  registerApplication({
    name: "@dronos/asset-management",
    app: loadApp("@dronos/asset-management"),
    activeWhen: ["/asset-management"],
    customProps: () => {
      return props;
    },
  });

  registerApplication({
    name: "@app/common",
    app: loadApp("@app/common"),
    activeWhen: () => location.pathname.startsWith("/common") && userLoggedIn,
    customProps: () => {
      return props;
    },
  });
}

registerApplication({
  name: "micro-canvas",
  app: loadApp("micro-canvas"),
  activeWhen: () =>
    specialAllowedRoutes.includes(location.pathname) ||
    (location.pathname.startsWith("/canvas") &&
      userLoggedIn &&
      userAccount === "paid"),
  customProps: () => {
    return props;
  },
});

registerApplication({
  name: "fe-micro-share-components",
  app: loadApp("fe-micro-share-components"),
  activeWhen: ["/"],
});

registerApplication({
  name: "fe-micro-ng-create-mission",
  app: loadApp("fe-micro-ng-create-mission"),
  activeWhen: ["/"],
});

start({
});

function loadApp(app) {
  return Promise.resolve()
    .then(() => {
      return System.import(app);
    })
    .then((app) => {
      return app;
    });
}

function checkPermission() {
  let hasPermission = false;
  const strUserRole = localStorage.getItem("user-role");
  if (strUserRole !== null) {
    const checking = userRole.find((role) => role.id === 1 || role.id === 2);
    hasPermission = checking !== undefined;
  } else {
    hasPermission = false;
  }
  return hasPermission;
}
if (userLoggedIn) {
  getSetInterval();
}
window.addEventListener("single-spa:before-routing-event", ({ detail }) => {
  const pathName = new URL(detail.newUrl).pathname;
  const basePath = pathName.split("/")[1];
  if (userLoggedIn) {
    if (publicRoutes.includes(`/${basePath}`)) {
      window.history.replaceState({}, "", "/");
    }
    if (
      pathName.startsWith("/canvas") &&
      !specialAllowedRoutes.includes(pathName)
    ) {
      if (userAccount === "free" || !checkPermission()) {
        // or navigate to subscription page
        window.history.replaceState({}, "", "/");
      }
    }
  } else {
    if (
      privateRoutes.includes(`/${basePath}`) &&
      !specialAllowedRoutes.includes(pathName)
    ) {
      window.history.replaceState({}, "", "/");
    }
  }
  listenShareComponentsCallback(pathName, detail);
});

function getSetInterval() {
  var tokenTime = JSON.parse(localStorage.getItem("dronos-token"));
  let getRefreshToken = setInterval(function () {
    let deadline = tokenTime.expiry + 3300000; // 55 minutes added
    let currentTime = new Date().getTime();
    let difference = deadline - currentTime;
    let minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

    if (minutes <= 5) {
      clearInterval(getRefreshToken);
      refreshToken();
    }
  }, 1000);
}

function refreshToken() {
  const config = {
    method: "post",
    url: "/account/auth/refresh-token",
  };

  axiosIns(config)
    .then((response) => {
      const newToken = response.data.token.substring(7);
      const currentTime = new Date().getTime();
      const item = {
        value: newToken,
        expiry: currentTime,
      };
      state.setDronosToken(item);

      const authToken = state.getDronosToken()?.value;
      const workspaceId = state.getUserInfo()?.member?.[0]?.workspace?._id;

      axiosIns.defaults.headers["Authorization"] = `Bearer ${authToken}`;
      axiosIns.defaults.headers["workspaceId"] = workspaceId;

      if (userLoggedIn) {
        getSetInterval();
      }
    })
    .catch((error) => {
      localStorage.removeItem("dronos-token");
      window.location.reload();
    });
}

function listenShareComponentsCallback(pathName, detail) {
  window.addEventListener("dronos:share-components:loaded", () => {
    shareComponentsLoaded = true;
    headerSidebarFooterEvent(pathName, detail);
  });

  if (shareComponentsLoaded) {
    headerSidebarFooterEvent(pathName, detail);
  }
}

function headerSidebarFooterEvent(pathName, detail) {
  let configName = "";
  if (pathName.startsWith("/canvas")) {
    configName = "canvas";
  }
  if (pathName.startsWith("/common")) {
    configName = "common";
  }
  if (pathName.startsWith("/canvas/amplitel")) {
    configName = "canvas/amplitel";
  }
  if (
    (pathName == "/" ||
      pathName.startsWith("/verify-otp") ||
      publicRoutes.includes(window.location.pathname)) &&
    !userLoggedIn
  ) {
    configName = "auth";
  }
  window.addEventListener(
    "single-spa:routing-event",
    () => {
      //finish mounting
      const getLocalStorage = localStorage.getItem("config-" + configName);

      const config =
        getLocalStorage !== null ? JSON.parse(getLocalStorage) : null;

      const customEvent = new CustomEvent("change-config", { detail: config });
      window.dispatchEvent(customEvent);
    },
    { once: true }
  );
}
